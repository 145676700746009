import React from "react"
import SEO from "@components/seo"
import * as data from "@data/pledge"
import Layout from "@components/layout"
import SendOneTimeDeposit from "@components/forms/SendOneTimeDeposit"

const OneTimeDepositPage = () => {
  const { autoDeposit } = data
  return (
    <Layout type="app">
      <SEO title="One-time Deposit" />
      <SendOneTimeDeposit />
    </Layout>
  )
}

export default OneTimeDepositPage
